const role = {
  'data': [
    {
      'id': 'admin',
      'name': '管理员',
      'description': '拥有所有权限',
      'status': 1,
      'creatorId': 'system',
      'createTime': 1497160610259,
      'deleted': 0,
      'permissions': [
        {
        'roleId': 'admin',
        'permissionId': 'comment',
        'permissionName': '营销管理',
        'actions': '[{"action":"add","defaultCheck":false,"description":"新增"},{"action":"query","defaultCheck":false,"description":"查询"},{"action":"get","defaultCheck":false,"description":"详情"},{"action":"edit","defaultCheck":false,"description":"修改"},{"action":"delete","defaultCheck":false,"description":"删除"}]',
        'actionEntitySet': [
          {
          'action': 'add',
          'description': '新增',
          'defaultCheck': false
        },
          {
            'action': 'query',
            'description': '查询',
            'defaultCheck': false
          },
          {
            'action': 'get',
            'description': '详情',
            'defaultCheck': true
          },
          {
            'action': 'edit',
            'description': '修改',
            'defaultCheck': true
          },
          {
            'action': 'delete',
            'description': '删除',
            'defaultCheck': false
          }],
        'actionList': ['delete', 'edit'],
        'dataAccess': null
      },
        {
          'roleId': 'admin',
          'permissionId': 'member',
          'permissionName': '会员管理',
          'actions': '[{"action":"add","defaultCheck":false,"description":"新增"},{"action":"query","defaultCheck":false,"description":"查询"},{"action":"get","defaultCheck":false,"description":"详情"},{"action":"edit","defaultCheck":false,"description":"修改"},{"action":"delete","defaultCheck":false,"description":"删除"}]',
          'actionEntitySet': [{
            'action': 'add',
            'description': '新增',
            'defaultCheck': false
          },
            {
              'action': 'query',
              'description': '查询',
              'defaultCheck': false
            },
            {
              'action': 'get',
              'description': '详情',
              'defaultCheck': false
            },
            {
              'action': 'edit',
              'description': '修改',
              'defaultCheck': false
            },
            {
              'action': 'delete',
              'description': '删除',
              'defaultCheck': false
            }
          ],
          'actionList': ['query', 'get', 'edit', 'delete'],
          'dataAccess': null
        },
        {
          'roleId': 'admin',
          'permissionId': 'menu',
          'permissionName': '菜单管理',
          'actions': '[{"action":"add","defaultCheck":false,"description":"新增"},{"action":"import","defaultCheck":false,"description":"导入"},{"action":"get","defaultCheck":false,"description":"详情"},{"action":"edit","defaultCheck":false,"description":"修改"}]',
          'actionEntitySet': [{
            'action': 'add',
            'description': '新增',
            'defaultCheck': false
          },
            {
              'action': 'import',
              'description': '导入',
              'defaultCheck': false
            },
            {
              'action': 'get',
              'description': '详情',
              'defaultCheck': false
            },
            {
              'action': 'edit',
              'description': '修改',
              'defaultCheck': false
            }
          ],
          'actionList': ['add', 'import'],
          'dataAccess': null
        },
        {
          'roleId': 'admin',
          'permissionId': 'order',
          'permissionName': '订单管理',
          'actions': '[{"action":"add","defaultCheck":false,"description":"新增"},{"action":"query","defaultCheck":false,"description":"查询"},{"action":"get","defaultCheck":false,"description":"详情"},{"action":"edit","defaultCheck":false,"description":"修改"},{"action":"delete","defaultCheck":false,"description":"删除"}]',
          'actionEntitySet': [{
            'action': 'add',
            'description': '新增',
            'defaultCheck': false
          },
            {
              'action': 'query',
              'description': '查询',
              'defaultCheck': false
            },
            {
              'action': 'get',
              'description': '详情',
              'defaultCheck': false
            },
            {
              'action': 'edit',
              'description': '修改',
              'defaultCheck': false
            },
            {
              'action': 'delete',
              'description': '删除',
              'defaultCheck': false
            }
          ],
          'actionList': ['query', 'add', 'get'],
          'dataAccess': null
        },
        {
          'roleId': 'admin',
          'permissionId': 'permission',
          'permissionName': '权限管理',
          'actions': '[{"action":"add","defaultCheck":false,"description":"新增"},{"action":"get","defaultCheck":false,"description":"详情"},{"action":"edit","defaultCheck":false,"description":"修改"},{"action":"delete","defaultCheck":false,"description":"删除"}]',
          'actionEntitySet': [{
            'action': 'add',
            'description': '新增',
            'defaultCheck': false
          },
            {
              'action': 'get',
              'description': '详情',
              'defaultCheck': false
            },
            {
              'action': 'edit',
              'description': '修改',
              'defaultCheck': false
            },
            {
              'action': 'delete',
              'description': '删除',
              'defaultCheck': false
            }
          ],
          'actionList': ['add', 'get', 'edit', 'delete'],
          'dataAccess': null
        },
        {
          'roleId': 'admin',
          'permissionId': 'role',
          'permissionName': '角色管理',
          'actions': '[{"action":"add","defaultCheck":false,"description":"新增"},{"action":"get","defaultCheck":false,"description":"详情"},{"action":"edit","defaultCheck":false,"description":"修改"},{"action":"delete","defaultCheck":false,"description":"删除"}]',
          'actionEntitySet': [{
            'action': 'add',
            'description': '新增',
            'defaultCheck': false
          },
            {
              'action': 'get',
              'description': '详情',
              'defaultCheck': false
            },
            {
              'action': 'edit',
              'description': '修改',
              'defaultCheck': false
            },
            {
              'action': 'delete',
              'description': '删除',
              'defaultCheck': false
            }
          ],
          'actionList': null,
          'dataAccess': null
        },
        {
          'roleId': 'admin',
          'permissionId': 'test',
          'permissionName': '测试权限',
          'actions': '[]',
          'actionEntitySet': [],
          'actionList': null,
          'dataAccess': null
        },
        {
          'roleId': 'admin',
          'permissionId': 'user',
          'permissionName': '用户管理',
          'actions': '[{"action":"add","defaultCheck":false,"description":"新增"},{"action":"import","defaultCheck":false,"description":"导入"},{"action":"get","defaultCheck":false,"description":"详情"},{"action":"edit","defaultCheck":false,"description":"修改"},{"action":"delete","defaultCheck":false,"description":"删除"},{"action":"export","defaultCheck":false,"description":"导出"}]',
          'actionEntitySet': [{
            'action': 'add',
            'description': '新增',
            'defaultCheck': false
          },
            {
              'action': 'import',
              'description': '导入',
              'defaultCheck': false
            },
            {
              'action': 'get',
              'description': '详情',
              'defaultCheck': false
            },
            {
              'action': 'edit',
              'description': '修改',
              'defaultCheck': false
            },
            {
              'action': 'delete',
              'description': '删除',
              'defaultCheck': false
            },
            {
              'action': 'export',
              'description': '导出',
              'defaultCheck': false
            }
          ],
          'actionList': ['add', 'get'],
          'dataAccess': null
        }
      ]
    },
    {
      'id': 'svip',
      'name': 'SVIP',
      'description': '超级会员',
      'status': 1,
      'creatorId': 'system',
      'createTime': 1532417744846,
      'deleted': 0,
      'permissions': [
        {
        'roleId': 'admin',
        'permissionId': 'comment',
        'permissionName': '营销管理',
        'actions': '[{"action":"add","defaultCheck":false,"description":"新增"},{"action":"query","defaultCheck":false,"description":"查询"},{"action":"get","defaultCheck":false,"description":"详情"},{"action":"edit","defaultCheck":false,"description":"修改"},{"action":"delete","defaultCheck":false,"description":"删除"}]',
        'actionEntitySet': [{
          'action': 'add',
          'description': '新增',
          'defaultCheck': false
        },
          {
            'action': 'query',
            'description': '查询',
            'defaultCheck': false
          },
          {
            'action': 'get',
            'description': '详情',
            'defaultCheck': false
          },
          {
            'action': 'edit',
            'description': '修改',
            'defaultCheck': false
          },
          {
            'action': 'delete',
            'description': '删除',
            'defaultCheck': false
          }
        ],
        'actionList': ['add', 'get', 'delete'],
        'dataAccess': null
      },
        {
          'roleId': 'admin',
          'permissionId': 'member',
          'permissionName': '会员管理',
          'actions': '[{"action":"add","defaultCheck":false,"description":"新增"},{"action":"query","defaultCheck":false,"description":"查询"},{"action":"get","defaultCheck":false,"description":"详情"},{"action":"edit","defaultCheck":false,"description":"修改"},{"action":"delete","defaultCheck":false,"description":"删除"}]',
          'actionEntitySet': [{
            'action': 'add',
            'description': '新增',
            'defaultCheck': false
          },
            {
              'action': 'query',
              'description': '查询',
              'defaultCheck': false
            },
            {
              'action': 'get',
              'description': '详情',
              'defaultCheck': false
            }
          ],
          'actionList': ['add', 'query', 'get'],
          'dataAccess': null
        },
        {
          'roleId': 'admin',
          'permissionId': 'menu',
          'permissionName': '菜单管理',
          'actions': '[{"action":"add","defaultCheck":false,"description":"新增"},{"action":"import","defaultCheck":false,"description":"导入"},{"action":"get","defaultCheck":false,"description":"详情"},{"action":"edit","defaultCheck":false,"description":"修改"}]',
          'actionEntitySet': [{
            'action': 'add',
            'description': '新增',
            'defaultCheck': false
          },
            {
              'action': 'import',
              'description': '导入',
              'defaultCheck': false
            },
            {
              'action': 'get',
              'description': '详情',
              'defaultCheck': false
            }
          ],
          'actionList': ['add', 'get'],
          'dataAccess': null
        },
        {
          'roleId': 'admin',
          'permissionId': 'order',
          'permissionName': '订单管理',
          'actions': '[{"action":"add","defaultCheck":false,"description":"新增"},{"action":"query","defaultCheck":false,"description":"查询"},{"action":"get","defaultCheck":false,"description":"详情"},{"action":"edit","defaultCheck":false,"description":"修改"},{"action":"delete","defaultCheck":false,"description":"删除"}]',
          'actionEntitySet': [{
            'action': 'add',
            'description': '新增',
            'defaultCheck': false
          },
            {
              'action': 'query',
              'description': '查询',
              'defaultCheck': false
            },
            {
              'action': 'get',
              'description': '详情',
              'defaultCheck': false
            },
            {
              'action': 'edit',
              'description': '修改',
              'defaultCheck': false
            }
          ],
          'actionList': ['add', 'query'],
          'dataAccess': null
        },
        {
          'roleId': 'admin',
          'permissionId': 'permission',
          'permissionName': '权限管理',
          'actions': '[{"action":"add","defaultCheck":false,"description":"新增"},{"action":"get","defaultCheck":false,"description":"详情"},{"action":"edit","defaultCheck":false,"description":"修改"},{"action":"delete","defaultCheck":false,"description":"删除"}]',
          'actionEntitySet': [{
            'action': 'add',
            'description': '新增',
            'defaultCheck': false
          },
            {
              'action': 'get',
              'description': '详情',
              'defaultCheck': false
            },
            {
              'action': 'edit',
              'description': '修改',
              'defaultCheck': false
            }
          ],
          'actionList': ['add', 'get', 'edit'],
          'dataAccess': null
        },
        {
          'roleId': 'admin',
          'permissionId': 'role',
          'permissionName': '角色管理',
          'actions': '[{"action":"add","defaultCheck":false,"description":"新增"},{"action":"get","defaultCheck":false,"description":"详情"},{"action":"edit","defaultCheck":false,"description":"修改"},{"action":"delete","defaultCheck":false,"description":"删除"}]',
          'actionEntitySet': [{
            'action': 'add',
            'description': '新增',
            'defaultCheck': false
          },
            {
              'action': 'edit',
              'description': '修改',
              'defaultCheck': false
            },
            {
              'action': 'delete',
              'description': '删除',
              'defaultCheck': false
            }
          ],
          'actionList': null,
          'dataAccess': null
        },
        {
          'roleId': 'admin',
          'permissionId': 'test',
          'permissionName': '测试权限',
          'actions': '[]',
          'actionEntitySet': [],
          'actionList': ['add', 'edit'],
          'dataAccess': null
        },
        {
          'roleId': 'admin',
          'permissionId': 'user',
          'permissionName': '用户管理',
          'actions': '[{"action":"add","defaultCheck":false,"description":"新增"},{"action":"import","defaultCheck":false,"description":"导入"},{"action":"get","defaultCheck":false,"description":"详情"},{"action":"edit","defaultCheck":false,"description":"修改"},{"action":"delete","defaultCheck":false,"description":"删除"},{"action":"export","defaultCheck":false,"description":"导出"}]',
          'actionEntitySet': [{
            'action': 'add',
            'description': '新增',
            'defaultCheck': false
          },
            {
              'action': 'import',
              'description': '导入',
              'defaultCheck': false
            },
            {
              'action': 'get',
              'description': '详情',
              'defaultCheck': false
            },
            {
              'action': 'edit',
              'description': '修改',
              'defaultCheck': false
            }
          ],
          'actionList': ['add'],
          'dataAccess': null
        }
      ]
    },
    {
      'id': 'user',
      'name': '普通会员',
      'description': '普通用户，只能查询',
      'status': 1,
      'creatorId': 'system',
      'createTime': 1497160610259,
      'deleted': 0,
      'permissions': [
        {
        'roleId': 'user',
        'permissionId': 'comment',
        'permissionName': '营销管理',
        'actions': '[{"action":"query","defaultCheck":false,"description":"查询"},{"action":"get","defaultCheck":false,"description":"详情"}]',
        'actionEntitySet': [{
          'action': 'query',
          'description': '查询',
          'defaultCheck': false
        },
          {
            'action': 'get',
            'description': '详情',
            'defaultCheck': false
          }
        ],
        'actionList': ['query'],
        'dataAccess': null
      },

        {
          'roleId': 'user',
          'permissionId': 'marketing',
          'permissionName': '营销管理',
          'actions': '[]',
          'actionEntitySet': [],
          'actionList': null,
          'dataAccess': null
        },
        {
          'roleId': 'user',
          'permissionId': 'member',
          'permissionName': '会员管理',
          'actions': '[{"action":"query","defaultCheck":false,"description":"查询"},{"action":"get","defaultCheck":false,"description":"详情"}]',
          'actionEntitySet': [{
            'action': 'query',
            'description': '查询',
            'defaultCheck': false
          },
            {
              'action': 'get',
              'description': '详情',
              'defaultCheck': false
            }
          ],
          'actionList': null,
          'dataAccess': null
        },
        {
          'roleId': 'user',
          'permissionId': 'menu',
          'permissionName': '菜单管理',
          'actions': '[]',
          'actionEntitySet': [],
          'actionList': null,
          'dataAccess': null
        },

        {
          'roleId': 'user',
          'permissionId': 'order',
          'permissionName': '订单管理',
          'actions': '[{"action":"query","defaultCheck":false,"description":"查询"},{"action":"get","defaultCheck":false,"description":"详情"}]',
          'actionEntitySet': [{
            'action': 'query',
            'description': '查询',
            'defaultCheck': false
          },
            {
              'action': 'get',
              'description': '详情',
              'defaultCheck': false
            }
          ],
          'actionList': null,
          'dataAccess': null
        },
        {
          'roleId': 'user',
          'permissionId': 'permission',
          'permissionName': '权限管理',
          'actions': '[]',
          'actionEntitySet': [],
          'actionList': null,
          'dataAccess': null
        },
        {
          'roleId': 'user',
          'permissionId': 'role',
          'permissionName': '角色管理',
          'actions': '[]',
          'actionEntitySet': [],
          'actionList': null,
          'dataAccess': null
        },

        {
          'roleId': 'user',
          'permissionId': 'test',
          'permissionName': '测试权限',
          'actions': '[]',
          'actionEntitySet': [],
          'actionList': null,
          'dataAccess': null
        },
        {
          'roleId': 'user',
          'permissionId': 'user',
          'permissionName': '用户管理',
          'actions': '[]',
          'actionEntitySet': [],
          'actionList': null,
          'dataAccess': null
        }
      ]
    }
  ],
  'pageSize': 10,
  'pageNo': 0,
  'totalPage': 1,
  'totalCount': 5
}

const permissionNoPager = [
  {
    'id': 'administration',
    'site': 'corporation',
    'name': '授权行政车辆',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 73},{"action":"add","defaultCheck":false,"description":"新增","pid": 71},{"action":"delete","defaultCheck":false,"description":"删除","pid": 72}]',
    'sptDaTypes': null,
    'optionalFields': null,
    'parents': null,
    'type': null,
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'delete'
    ]
  },
  {
    'id': 'inout_application',
    'site': 'corporation',
    'name': '进出区申请单',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 65},{"action":"add","defaultCheck":false,"description":"新增","pid": 62},{"action":"edit","defaultCheck":false,"description":"修改","pid": 64},{"action":"delete","defaultCheck":false,"description":"删除","pid": 63}]',
    'sptDaTypes': null,
    'optionalFields': null,
    'parents': null,
    'type': null,
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'edit',
      'delete'
    ]
  },
  {
    'id': 'inout_book',
    'name': '进出区台账目',
    'site': 'corporation',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 67},{"action":"add","defaultCheck":false,"description":"新增","pid": 66}]',
    'sptDaTypes': null,
    'optionalFields': null,
    'parents': null,
    'type': null,
    'deleted': 0,
    'actions': [
      'add',
      'query'
    ]
  },
  {
    'id': 'temporary',
    'site': 'corporation',
    'name': '入区登记',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查看"}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query'
    ]
  },
  {
    'id': 'bank_account',
    'name': '银行账户',
    'site': 'corporation',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"获取企业资产银行账户","pid": 232}, {"action":"add","defaultCheck":false,"description":"新增企业资产银行账户","pid": 233}, {"action":"delete","defaultCheck":false,"description":"删除企业资产银行账户","pid": 234}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query',
      'add',
      'delete'
    ]
  },
  {
    'id': 'contract_information',
    'site': 'corporation',
    'name': '合同信息',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 56}]',
    'sptDaTypes': null,
    'optionalFields': null,
    'parents': null,
    'type': null,
    'deleted': 0,
    'actions': [
      'query'
    ]
  },
  {
    'id': 'contract_template',
    'site': 'corporation',
    'name': '合同模板',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 52},{"action":"add","defaultCheck":false,"description":"新增","pid": 50},{"action":"delete","defaultCheck":false,"description":"删除","pid": 51}]',
    'sptDaTypes': null,
    'optionalFields': null,
    'parents': null,
    'type': null,
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'delete'
    ]
  },
  {
    'id': 'electricity_fees',
    'site': 'corporation',
    'name': '电费账单',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 86},{"action":"add","defaultCheck":false,"description":"新增","pid": 83},{"action":"edit","defaultCheck":false,"description":"修改","pid": 85},{"action":"delete","defaultCheck":false,"description":"删除","pid": 84}]',
    'sptDaTypes': null,
    'optionalFields': null,
    'parents': null,
    'type': null,
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'edit',
      'delete'
    ]
  },
  {
    'id': 'water_fees',
    'site': 'corporation',
    'name': '水费账单',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 82},{"action":"add","defaultCheck":false,"description":"新增","pid": 80},{"action":"edit","defaultCheck":false,"description":"修改","pid": 81}]',
    'sptDaTypes': null,
    'optionalFields': null,
    'parents': null,
    'type': null,
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'edit'
    ]
  },
  {
    'id': 'rent_fees',
    'site': 'corporation',
    'name': '租金账单',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 61},{"action":"add","defaultCheck":false,"description":"新增","pid": 58},{"action":"edit","defaultCheck":false,"description":"修改","pid": 60},{"action":"delete","defaultCheck":false,"description":"删除","pid": 59}]',
    'sptDaTypes': null,
    'optionalFields': null,
    'parents': null,
    'type': null,
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'edit',
      'delete'
    ]
  },
  {
    'id': 'property_fees',
    'site': 'corporation',
    'name': '物业账单',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 61},{"action":"add","defaultCheck":false,"description":"新增","pid": 58},{"action":"edit","defaultCheck":false,"description":"修改","pid": 60},{"action":"delete","defaultCheck":false,"description":"删除","pid": 59}]',
    'sptDaTypes': null,
    'optionalFields': null,
    'parents': null,
    'type': null,
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'edit',
      'delete'
    ]
  },
  {
    'id': 'payment_ticket',
    'site': 'corporation',
    'name': '付款凭证',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 90},{"action":"add","defaultCheck":false,"description":"新增","pid": 87},{"action":"edit","defaultCheck":false,"description":"修改","pid": 89},{"action":"delete","defaultCheck":false,"description":"删除","pid": 88}]',
    'sptDaTypes': null,
    'optionalFields': null,
    'parents': null,
    'type': null,
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'edit',
      'delete'
    ]
  },
  {
    'id': 'fapiao_application',
    'site': 'corporation',
    'name': '发票申请',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 93},{"action":"add","defaultCheck":false,"description":"新增","pid": 91},{"action":"edit","defaultCheck":false,"description":"修改","pid": 92}]',
    'sptDaTypes': null,
    'optionalFields': null,
    'parents': null,
    'type': null,
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'edit'
    ]
  },
  {
    'id': 'undertaking',
    'site': 'corporation',
    'name': '承诺书',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 70},{"action":"add","defaultCheck":false,"description":"新增","pid": 68},{"action":"delete","defaultCheck":false,"description":"删除","pid": 69}]',
    'sptDaTypes': null,
    'optionalFields': null,
    'parents': null,
    'type': null,
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'delete'
    ]
  },
  {
    'id': 'fapiao_information',
    'site': 'corporation',
    'name': '发票信息',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询"},{"action":"get","defaultCheck":false,"description":"详情"},{"action":"add","defaultCheck":false,"description":"新增"},{"action":"edit","defaultCheck":false,"description":"修改"},{"action":"delete","defaultCheck":false,"description":"删除"}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'get',
      'edit',
      'delete'
    ]
  },
  {
    'id': 'user_information',
    'site': 'corporation',
    'name': '用户信息',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 4},{"action":"add","defaultCheck":false,"description":"新增","pid": 1},{"action":"edit","defaultCheck":false,"description":"修改","pid": 3},{"action":"delete","defaultCheck":false,"description":"删除","pid": 2}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'edit',
      'delete'
    ]
  },
  {
    'id': 'user_settings',
    'site': 'corporation',
    'name': '用户设置',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"edit","defaultCheck":false,"description":"重置密码","pid": 7}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'edit'
    ]
  },
  {
    'id': 'tag',
    'name': '企业标签',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 23},{"action":"add","defaultCheck":false,"description":"新增","pid": 20},{"action":"delete","defaultCheck":false,"description":"删除","pid": 21},{"action":"update","defaultCheck":false,"description":"更新","pid": 22}]',
    'sptDaTypes': null,
    'optionalFields': null,
    'parents': null,
    'type': null,
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'delete',
      'update'
    ]
  },
  {
    'id': 'water_meter_record',
    'name': '抄表',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 79},{"action":"add","defaultCheck":false,"description":"新增","pid": 77},{"action":"delete","defaultCheck":false,"description":"删除","pid": 78}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'edit',
      'delete'
    ]
  },
  {
    'id': 'water_meter',
    'name': '水表',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 76},{"action":"add","defaultCheck":false,"description":"新增","pid": 74},{"action":"delete","defaultCheck":false,"description":"删除","pid": 75}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'edit',
      'delete'
    ]
  },
  {
    'id': 'corporation',
    'name': '企业',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 19},{"action":"add","defaultCheck":false,"description":"新增","pid": 16},{"action":"edit","defaultCheck":false,"description":"修改","pid": 18},{"action":"delete","defaultCheck":false,"description":"删除","pid": 17}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'edit',
      'delete'
    ]
  },
  {
    'id': 'notification',
    'name': '通知',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 101},{"action":"add","defaultCheck":false,"description":"新增","pid": 99},{"action":"delete","defaultCheck":false,"description":"删除","pid": 100}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'delete'
    ]
  },
  {
    'id': 'role',
    'name': '权限组',
    'site': 'corporation',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 11},{"action":"add","defaultCheck":false,"description":"新增","pid": 8},{"action":"edit","defaultCheck":false,"description":"修改","pid": 10},{"action":"delete","defaultCheck":false,"description":"删除","pid": 9}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'edit',
      'delete'
    ]
  }, {
    'id': 'user_peizhi',
    'name': '用户权限配置',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"获取用户与权限组","pid": 14},{"action":"get","defaultCheck":false,"description":"获取权限与权限组","pid": 12},{"action":"edit","defaultCheck":false,"description":"更新用户与权限组","pid": 15},{"action":"delete","defaultCheck":false,"description":"更新权限与权限组","pid": 13},{"action":"import","defaultCheck":false,"description":"添加企业与企业标签关联","pid": 24},{"action":"export","defaultCheck":false,"description":"删除企业与企业标签关联","pid": 25}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'edit',
      'delete'
    ]
  }, {
    'id': 'inventory',
    'name': '仓库',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"获取","pid": 94},{"action":"export","defaultCheck":false,"description":"导出","pid": 94}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query',
      'get'
    ]
  }, {
    'id': 'muck',
    'name': '渣土',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 97},{"action":"add","defaultCheck":false,"description":"新增","pid": 95},{"action":"delete","defaultCheck":false,"description":"删除","pid": 96}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query',
      'get',
      'delete'
    ]
  },
  {
    'id': 'customs_entry_1210',
    'site': 'corporation',
    'name': '1210报关',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 203},{"action":"edit","defaultCheck":false,"description":"编辑","pid": 202},{"action":"delete","defaultCheck":false,"description":"删除","pid": 201},{"action":"add","defaultCheck":false,"description":"创建","pid": 200}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query',
      'edit',
      'delete',
      'add'
    ]
  },
  {
    'id': 'customs_config_1210',
    'site': 'corporation',
    'name': '1210配置',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 206},{"action":"edit","defaultCheck":false,"description":"编辑","pid": 205}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query',
      'edit'
    ]
  },
  {
    'id': 'wms_warehouse',
    'site': 'corporation',
    'name': 'wms仓库',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 300},{"action":"add","defaultCheck":false,"description":"创建","pid": 301},{"action":"edit","defaultCheck":false,"description":"编辑","pid": 302},{"action":"delete","defaultCheck":false,"description":"删除","pid": 303}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query',
      'add',
      'edit',
      'delete'
    ]
  },
  {
    'id': 'wms_cargo_space',
    'site': 'corporation',
    'name': 'wms库位',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 304},{"action":"add","defaultCheck":false,"description":"创建","pid": 305},{"action":"edit","defaultCheck":false,"description":"编辑","pid": 306},{"action":"delete","defaultCheck":false,"description":"删除","pid": 307}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query',
      'edit',
      'add',
      'delete'
    ]
  },
  {
    'id': 'common_warehouse_inventory',
    'site': 'corporation',
    'name': '公共仓库存',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 352}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query'
    ]
  },
  {
    'id': 'wms_goods',
    'site': 'corporation',
    'name': 'wms货品',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 308},{"action":"add","defaultCheck":false,"description":"创建","pid": 309},{"action":"edit","defaultCheck":false,"description":"编辑","pid": 310}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query',
      'edit',
      'add'
    ]
  },
  {
    'id': 'wms_commodity',
    'site': 'corporation',
    'name': 'wms商品',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 311},{"action":"add","defaultCheck":false,"description":"创建","pid": 312},{"action":"delete","defaultCheck":false,"description":"删除","pid": 313}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query',
      'delete',
      'add'
    ]
  },
  {
    'id': 'wms_commodity_purchase',
    'site': 'corporation',
    'name': 'wms商品入库',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 314},{"action":"add","defaultCheck":false,"description":"创建","pid": 315},{"action":"edit","defaultCheck":false,"description":"编辑","pid": 316},{"action":"delete","defaultCheck":false,"description":"删除","pid": 317}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query',
      'edit',
      'add',
      'delete'
    ]
  },
  {
    'id': 'wms_picks',
    'site': 'corporation',
    'name': 'wms拣货单',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 318},{"action":"edit_picker","defaultCheck":false,"description":"批量编辑拣货人","pid": 319},{"action":"edit_packer","defaultCheck":false,"description":"批量编辑打包人","pid": 320},{"action":"edit_picker_packer","defaultCheck":false,"description":"编辑拣货人&打包人","pid": 321},{"action":"edit","defaultCheck":false,"description":"编辑状态","pid": 322}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query',
      'edit_picker',
      'edit_packer',
      'edit_picker_packer',
      'edit'
    ]
  },
  {
    'id': 'wms_consumable',
    'site': 'corporation',
    'name': 'wms耗材',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 326},{"action":"add","defaultCheck":false,"description":"创建","pid": 327},{"action":"delete","defaultCheck":false,"description":"删除","pid": 328}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query',
      'add',
      'delete'
    ]
  },
  {
    'id': 'logistics_config',
    'site': 'corporation',
    'name': '快递配置',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 359},{"action":"add","defaultCheck":false,"description":"创建","pid": 359},{"action":"edit","defaultCheck":false,"description":"编辑","pid": 359},{"action":"delete","defaultCheck":false,"description":"删除","pid": 359}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query',
      'add',
      'edit',
      'delete'
    ]
  },
  {
    'id': 'wx_applet',
    'site': 'corporation',
    'name': '小程序',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"get","defaultCheck":false,"description":"快速注册","pid": 221},{"action":"import","defaultCheck":false,"description":"小程序修改头像","pid": 212},{"action":"edit","defaultCheck":false,"description":"小程序代码上传","pid": 213},{"action":"query","defaultCheck":false,"description":"代码提交审核","pid": 214},{"action":"delete","defaultCheck":false,"description":"小程序修改简介","pid": 215},{"action":"export","defaultCheck":false,"description":"小程序设置类目","pid": 216},{"action":"add","defaultCheck":false,"description":"复用公众号注册","pid": 223}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query',
      'get',
      'add',
      'edit',
      'import',
      'export',
      'delete'
    ]
  },
  {
    'id': 'wx_merchants',
    'site': 'corporation',
    'name': '微信支付',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"add","defaultCheck":false,"description":"微信支付特约商户","pid": 217}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'get'
    ]
  },
  {
    'id': 'wx_applet_config',
    'site': 'corporation',
    'name': '微信小程序配置',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"add","defaultCheck":false,"description":"微信小程序企业配置","pid": 228}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'add'
    ]
  },
  {
    'id': 'wx_manage_order',
    'site': 'corporation',
    'name': '小程序商城管理',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 210}, {"action":"get","defaultCheck":false,"description":"模板列表","pid": 229}, {"action":"add","defaultCheck":false,"description":"商品类目","pid": 230}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query',
      'get',
      'add'
    ]
  },
  {
    'id': 'export_order',
    'site': 'corporation',
    'name': '单据',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 227},{"action":"add","defaultCheck":false,"description":"创建","pid": 224},{"action":"edit","defaultCheck":false,"description":"修改","pid": 226},{"action":"delete","defaultCheck":false,"description":"删除","pid": 225}]',
    'sptDaTypes': null,
    'optionalFields': null,
    'parents': null,
    'type': null,
    'deleted': 0,
    'actions': [
      'add',
      'edit',
      'query',
      'delete'
    ]
  }
]

const defaultCorporationPermissions = [
  {
    'id': 'administration',
    'site': 'corporation',
    'name': '授权行政车辆',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":true,"description":"查询","pid": 73},{"action":"add","defaultCheck":true,"description":"新增","pid": 71},{"action":"delete","defaultCheck":true,"description":"删除","pid": 72}]',
    'sptDaTypes': null,
    'optionalFields': null,
    'parents': null,
    'type': null,
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'delete'
    ]
  },
  {
    'id': 'inout_application',
    'site': 'corporation',
    'name': '进出区申请单',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":true,"description":"查询","pid": 65},{"action":"add","defaultCheck":true,"description":"新增","pid": 62},{"action":"edit","defaultCheck":true,"description":"修改","pid": 64},{"action":"delete","defaultCheck":true,"description":"删除","pid": 63}]',
    'sptDaTypes': null,
    'optionalFields': null,
    'parents': null,
    'type': null,
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'edit',
      'delete'
    ]
  },
  {
    'id': 'inout_book',
    'name': '进出区台账目',
    'site': 'corporation',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":true,"description":"查询","pid": 67},{"action":"add","defaultCheck":true,"description":"新增","pid": 66}]',
    'sptDaTypes': null,
    'optionalFields': null,
    'parents': null,
    'type': null,
    'deleted': 0,
    'actions': [
      'add',
      'query'
    ]
  },
  {
    'id': 'temporary',
    'site': 'corporation',
    'name': '入区登记',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":true,"description":"查看"}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query'
    ]
  },
  {
    'id': 'contract_information',
    'site': 'corporation',
    'name': '合同信息',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":true,"description":"查询","pid": 56}]',
    'sptDaTypes': null,
    'optionalFields': null,
    'parents': null,
    'type': null,
    'deleted': 0,
    'actions': [
      'query'
    ]
  },
  {
    'id': 'contract_template',
    'site': 'corporation',
    'name': '合同模板',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":true,"description":"查询","pid": 52},{"action":"add","defaultCheck":true,"description":"新增","pid": 50},{"action":"delete","defaultCheck":true,"description":"删除","pid": 51}]',
    'sptDaTypes': null,
    'optionalFields': null,
    'parents': null,
    'type': null,
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'delete'
    ]
  },
  {
    'id': 'electricity_fees',
    'site': 'corporation',
    'name': '电费账单',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":true,"description":"查询","pid": 86},{"action":"add","defaultCheck":true,"description":"新增","pid": 83},{"action":"edit","defaultCheck":true,"description":"修改","pid": 85},{"action":"delete","defaultCheck":true,"description":"删除","pid": 84}]',
    'sptDaTypes': null,
    'optionalFields': null,
    'parents': null,
    'type': null,
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'edit',
      'delete'
    ]
  },
  {
    'id': 'electricity_meter_balance',
    'site': 'corporation',
    'name': '电表余额',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":true,"description":"查询","pid": 248},{"action":"add","defaultCheck":true,"description":"新增","pid": 249}]',
    'sptDaTypes': null,
    'optionalFields': null,
    'parents': null,
    'type': null,
    'deleted': 0,
    'actions': [
      'query',
      'add'
    ]
  },
  {
    'id': 'water_fees',
    'site': 'corporation',
    'name': '水费账单',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":true,"description":"查询","pid": 82},{"action":"add","defaultCheck":true,"description":"新增","pid": 80},{"action":"edit","defaultCheck":true,"description":"修改","pid": 81}]',
    'sptDaTypes': null,
    'optionalFields': null,
    'parents': null,
    'type': null,
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'edit'
    ]
  },
  {
    'id': 'rent_fees',
    'site': 'corporation',
    'name': '租金账单',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":true,"description":"查询","pid": 61},{"action":"add","defaultCheck":true,"description":"新增","pid": 58},{"action":"edit","defaultCheck":true,"description":"修改","pid": 60},{"action":"delete","defaultCheck":true,"description":"删除","pid": 59}]',
    'sptDaTypes': null,
    'optionalFields': null,
    'parents': null,
    'type': null,
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'edit',
      'delete'
    ]
  },
  {
    'id': 'property_fees',
    'site': 'corporation',
    'name': '物业账单',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":true,"description":"查询","pid": 61},{"action":"add","defaultCheck":true,"description":"新增","pid": 58},{"action":"edit","defaultCheck":true,"description":"修改","pid": 60},{"action":"delete","defaultCheck":true,"description":"删除","pid": 59}]',
    'sptDaTypes': null,
    'optionalFields': null,
    'parents': null,
    'type': null,
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'edit',
      'delete'
    ]
  },
  {
    'id': 'guarantee_fees',
    'site': 'corporation',
    'name': '保证金账单',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 181}]',
    'sptDaTypes': null,
    'optionalFields': null,
    'parents': null,
    'type': null,
    'deleted': 0,
    'actions': [
      'query'
    ]
  },
  {
    'id': 'payment_ticket',
    'site': 'corporation',
    'name': '付款凭证',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":true,"description":"查询","pid": 90},{"action":"add","defaultCheck":true,"description":"新增","pid": 87},{"action":"edit","defaultCheck":true,"description":"修改","pid": 89},{"action":"delete","defaultCheck":true,"description":"删除","pid": 88}]',
    'sptDaTypes': null,
    'optionalFields': null,
    'parents': null,
    'type': null,
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'edit',
      'delete'
    ]
  },
  {
    'id': 'fapiao_application',
    'site': 'corporation',
    'name': '发票申请',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":true,"description":"查询","pid": 93},{"action":"add","defaultCheck":true,"description":"新增","pid": 91},{"action":"edit","defaultCheck":true,"description":"修改","pid": 92}]',
    'sptDaTypes': null,
    'optionalFields': null,
    'parents': null,
    'type': null,
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'edit'
    ]
  },
  {
    'id': 'bank_account',
    'name': '银行账户',
    'site': 'corporation',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"获取企业资产银行账户","pid": 232}, {"action":"add","defaultCheck":false,"description":"新增企业资产银行账户","pid": 233}, {"action":"delete","defaultCheck":false,"description":"删除企业资产银行账户","pid": 234}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query',
      'add',
      'delete'
    ]
  },
  {
    'id': 'undertaking',
    'site': 'corporation',
    'name': '承诺书',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":true,"description":"查询","pid": 70},{"action":"add","defaultCheck":true,"description":"新增","pid": 68},{"action":"delete","defaultCheck":true,"description":"删除","pid": 69}]',
    'sptDaTypes': null,
    'optionalFields': null,
    'parents': null,
    'type': null,
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'delete'
    ]
  },
  {
    'id': 'fapiao_information',
    'site': 'corporation',
    'name': '发票信息',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":true,"description":"查询"},{"action":"get","defaultCheck":true,"description":"详情"},{"action":"add","defaultCheck":true,"description":"新增"},{"action":"edit","defaultCheck":true,"description":"修改"},{"action":"delete","defaultCheck":true,"description":"删除"}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'get',
      'edit',
      'delete'
    ]
  },
  {
    'id': 'applet_order',
    'site': 'corporation',
    'name': '小程序订单',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"edit","defaultCheck":false,"description":"订单取消","pid": 354}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'edit'
    ]
  },
  {
    'id': 'user_information',
    'site': 'corporation',
    'name': '用户信息',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":true,"description":"查询","pid": 4},{"action":"add","defaultCheck":true,"description":"新增","pid": 1},{"action":"edit","defaultCheck":true,"description":"修改","pid": 3},{"action":"delete","defaultCheck":true,"description":"删除","pid": 2}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'edit',
      'delete'
    ]
  },
  {
    'id': 'user_settings',
    'site': 'corporation',
    'name': '用户设置',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"edit","defaultCheck":true,"description":"重置密码","pid": 7}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'edit'
    ]
  },
  {
    'id': 'role',
    'site': 'corporation',
    'name': '权限组配置',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":true,"description":"查询","pid": 11},{"action":"add","defaultCheck":true,"description":"新增","pid": 8},{"action":"edit","defaultCheck":true,"description":"修改","pid": 10},{"action":"delete","defaultCheck":true,"description":"删除","pid": 9}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'edit',
      'delete'
    ]
  }
]

export { role, permissionNoPager, defaultCorporationPermissions }
